<template>
  <!-- 登录页 -->
  <div class="login">
    <div class="content">
      <!-- 左侧登录 -->
      <div class="leftBox">
        <div class="logo">天天拳击</div>
        <el-form
          :model="loginForm"
          :rules="loginRules"
          ref="loginForm"
          :show-message="false"
        >
          <el-form-item prop="account">
            <!--   prefix-icon="el-icon-user" -->
            <el-input v-model="loginForm.account" placeholder="请输入账号">
              <p slot="prefix" class="iptImg">
                <img src="@/assets/login/user.png" alt="" />
              </p>
            </el-input>
          </el-form-item>
          <el-form-item prop="passwords">
            <!--  prefix-icon="el-icon-unlock" -->
            <el-input
              v-model="loginForm.passwords"
              placeholder="请输入密码"
              show-password
            >
              <p slot="prefix" class="iptImg">
                <img src="@/assets/login/password.png" alt="" />
              </p>
            </el-input>
          </el-form-item>
          <!-- <el-form-item>
            <el-checkbox v-model="loginForm.checked">记住密码</el-checkbox>
          </el-form-item>-->
          <el-form-item>
            <el-button
              type="primary"
              @click="submitForm('loginForm')"
              :loading="loginLoading"
              class="blue-btn"
              >登录</el-button
            >
          </el-form-item>
        </el-form>
      </div>
    </div>
  </div>
</template>

<script>
// 引入md5加密
// import md5 from "js-md5";
export default {
  data() {
    return {
      loginLoading: false,
      loginForm: {
        account: "",
        passwords: "",
        checked: false,
      },
      loginRules: {
        phone: [
          {
            required: true,
            validator: this.validator.phone,
            trigger: "blur",
          },
        ],
        account: [{ required: true, message: "请输入账号", trigger: "blur" }],
        passwords: [{ required: true, message: "请输入密码", trigger: "blur" }],
      },
    };
  },
  created() {},
  mounted() {},
  methods: {
    // 登录
    submitForm(formName) {
      this.$refs[formName].validate((valid, values) => {
        if (valid) {
          this.loginLoading = true;
          // this.loginForm.password = md5(this.loginForm.passwords);
          this.loginForm.password = this.loginForm.passwords;
          this.$store
            .dispatch("enterLogin", this.loginForm)
            .then(() => {
              this.$router.push({ path: this.redirect || "/" }).catch(() => {});
              this.loginLoading = false;
            })
            .catch(() => {
              this.loginLoading = false;
            });
        } else {
          // console.log(valid, values);
          // console.log(
          //   "Object.values(values) :>> ",
          //   Object.values(values)[0][0].message
          // );
          this.$message.info(Object.values(values)[0][0].message);
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss" scoped>
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  background: url("../../assets/login/bg.png") no-repeat;
  background-size: 100% 100%;
  opacity: 0.8;
  height: 100vh;
  .content {
    display: flex;
    justify-content: flex-start;
    width: 70%;
    .leftBox {
      height: 100%;
      box-sizing: border-box;
      border-radius: 8px;
      padding: 106px 22px 78px;
      display: flex;
      flex-direction: column;
      // align-items: center;
      .logo {
        margin-bottom: 67px;
        color: #ffffff;
        font-size: 48px;
        font-weight: 800;
        letter-spacing: 4px;
      }
      ::v-deep .el-form {
        .el-form-item {
          width: 380px;
          margin-bottom: 30px;
          .el-form-item__content {
            line-height: 1;
            .el-checkbox {
              color: #626a73;
              font-size: 18px;
              .el-checkbox__input {
                .el-checkbox__inner {
                  width: 30px;
                  height: 30px;
                }
                .el-checkbox__inner::after {
                  height: 17px;
                  width: 8px;
                  top: 15px;
                  margin-top: -13px;
                  left: 15px;
                  margin-left: -6px;
                  border: 3px solid #fff;
                  border-left: none;
                  border-top: none;
                }
              }
              .el-checkbox__label {
                color: #626a73;
                font-size: 21px;
              }
            }
          }
          .el-input {
            border: none;
            .el-input__inner {
              height: 66px;
              line-height: 66px;
              border-radius: 10px;
              padding: 0 23px 0 69px;
              color: #4e5969;
              font-size: 22px;
            }
            .el-input__inner:focus {
              border-color: #4e5969;
            }
            .el-input__prefix {
              top: 50%;
              margin-top: -13px;
              left: 24px;
              .iptImg {
                width: 24px;
                height: 24px;
                img {
                  width: 100%;
                }
              }
            }
          }
          input::-webkit-input-placeholder {
            /* WebKit browsers */
            color: #4e5969;
          }
          .el-button {
            width: 100%;
            padding: 19px 0;
            color: #ffffff;
            border-radius: 10px;
            line-height: 1;
            margin-top: 30px;
            letter-spacing: 4px;
            span {
              font-size: 22px;
              line-height: 1;
            }
            filter: drop-shadow(0 5px 8px #6c24ec);
            .el-icon-loading {
              font-size: 22px;
            }
          }
          .el-button:hover {
            background: #6c24ec;
          }
        }
        .is-error {
          .el-input__inner {
            border-color: #dcdfe6;
          }
        }
        .el-form-item:last-child {
          margin-bottom: 0;
        }
      }
    }
  }
}
</style>
